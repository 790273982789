const lawyerRoleFunctions = {
    'Admin': [
        'Associé',
        'Associé Gérant',
        'Associé - Responsable de département',
    ],
    'User': [
        'Collaborateur',
        'Collaborateur Senior',
        'Collaborateur Junior',
        'Documentaliste',
        'Assistnte',
        'Stagiaire',
    ]
}

const otherRoleFunctions = {
    'Admin': [
        'Directeur/Responsable'
    ],
    'User': [
        'Assistant'
    ]
}


// check if the user is partner(associé)
export const isAssociate = (userRoles, userFonction) => {
    if(!userRoles || !userFonction) return false

    if (
        userRoles.includes('ROLE_ADMIN') && 
        lawyerRoleFunctions['Admin'].includes(userFonction)
    ) {
        return true
    }else {
        return false
    }
}


export const isDirector = (userRoles, userFonction) => {
    if(!userRoles || !userFonction) return false

    if (
        userRoles.includes('ROLE_ADMIN') && 
        otherRoleFunctions['Admin'].includes(userFonction)
    ) {
        return true
    }else {
        return false
    }
}

export const isAccountant = (userRoles, userSpeciality) => {
    if(!userRoles || !userSpeciality ) return false

    const userSpecialityArr = userSpeciality.split(',')
    if ( 
        userSpecialityArr.includes('Comptabilité')
    ) {
        return true
    }else{
        return false
    }
}

// check if the user can create a team
export const canCreateTeam = (userRoles, userFonction, userSpeciality, teamSpeciality) => {
    if(userRoles == null || userFonction == null || teamSpeciality == null || userSpeciality == null) return false

    const userSpecialityArr = userSpeciality.split(',')
    if(!userSpecialityArr.includes(teamSpeciality)) return false
    
    if (
        userRoles.includes('ROLE_ADMIN') && 
        (lawyerRoleFunctions['Admin'].includes(userFonction) || otherRoleFunctions['Admin'].includes(userFonction))
    ) {
        return true
    } else {
        return false
    }
}

export const canManageInternalUser = (userRoles, userFonction, userSpeciality) => {   
    if(!userRoles || !userFonction || !userSpeciality) return false
    
    const userSpecialityArr = userSpeciality.split(',')
    if (
        userRoles.includes('ROLE_ADMIN') && 
        userSpecialityArr.includes('Administration') &&
        (lawyerRoleFunctions['Admin'].includes(userFonction) || otherRoleFunctions['Admin'].includes(userFonction))
    ){
        return true
    } else {
        return false
    }
}


export const canAccessProject = (userFonction) => {
    if(!userFonction) return false

    if(lawyerRoleFunctions['Admin'].includes(userFonction) || lawyerRoleFunctions['User'].includes(userFonction)){
        return true
    }else{
        return false
    }
}

export const canAccessBilling = (userRoles, userFonction, userSpeciality) => {
    if(userRoles == null || userFonction == null || userSpeciality == null) return false
    
    const userSpecialityArr = userSpeciality.split(',')
    if (
        (userSpecialityArr.includes('Comptabilité')) ||
        (userRoles.includes('ROLE_ADMIN') && lawyerRoleFunctions['Admin'].includes(userFonction))
    ){
        return true
    } else {
        return false
    }
}
